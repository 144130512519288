<template>
  <div class="contents-area foreign-registers-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1>{{ translations("ForeignRegisters") }}</h1>
          <Breadcrumbs />
        </div>
        <CountryPanels />
        <div class="seo-contact-form">
          <div
            class="seo-content"
            v-html="listSettings.foreignregSettings.seo_text"
          ></div>
          <div class="consult-cont">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL, metrikaCode } from "@/config";
import { mapGetters, mapActions } from "vuex";
import CountryPanels from "@/components/egrul/CountryPanels";
import ContactForm from "@/components/app/forms/ContactForm";

export default {
  name: "ForeignRegisters",
  components: { CountryPanels, ContactForm },
  metaInfo() {
    return {
      title:
        this.translations("ForeignRegisters") + " | " + this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listSettings.foreignregSettings.meta_desc,
        },
        {
          name: "keywords",
          content: this.listSettings.foreignregSettings.meta_keywords,
        },
      ],
    };
  },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      goalReached: false,
    };
  },
  computed: {
    ...mapGetters([
      "isCountriesLoaded",
      "isCountriesLoading",
      "listLang",
      "listSettings",
      "curCountryId",
      "ListLoggedUser",
      "curCountry",
      "translations",
    ]),
  },
  methods: {
    ...mapActions(["fetchCountries"]),
    reachPageGoal() {
      if (this.ListLoggedUser.boughts === 0) {
        if (!this.goalReached) {
          this.$metrika.reachGoal("goalPagesVisit", {
            page: this.translations("ForeignRegisters"),
            //        countryCode: this.curCountry.code,
            //        country: this.curCountry.text,
          });
          this.goalReached = true;
        }
      }
    },
  },
  async mounted() {
    if (!this.isCountriesLoaded && !this.isCountriesLoading) {
      await this.fetchCountries(this.$cookies.get("user_language"));
    }
    document.addEventListener("yacounter" + metrikaCode + "inited", () => {
      this.reachPageGoal();
    });
    if (this.$metrika) {
      this.reachPageGoal();
    }
  },
};
</script>
